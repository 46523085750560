import React from "react";
import Footer from "./Footer";

const ProjectCard = ({ image, title, description, git, technologies }) => {
  return (
    <div className="max-w-sm sm:max-w-sm md:max-w-sm bg-gray-900 border border-neutral-100 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <a href="#">
        <img className="w-full rounded-t-lg h-[300px] " src={image} alt="" />
      </a>
      <div className="p-4 sm:p-6">
        <a href="#">
          <h5 className="text-2xl sm:text-xl md:text-2xl lg:text-3xl font-bold tracking-tight text-white bg-clip-text text-transparent bg-gradient-to-r from-yellow-200 to-pink-500">
            {title}
          </h5>
        </a>
        <p className="font-normal text-sm sm:text-base md:text-lg text-gray-300 dark:text-gray-400">
          {description}
        </p>
      </div>
      <div className="m-2 sm:m-4 lg:m-6 flex justify-between">
        <div className="flex flex-wrap gap-2 pl-2">
          {technologies.map((tag, index) => (
            <p key={`${index}-${tag}`} className="text-[14px] text-blue-500">
              #{tag}
            </p>
          ))}
        </div>
        <a
          href={git}
          className="text-red-300 border border-gray-200 rounded-lg shadow p-1 sm:p-2 lg:p-3 hover:text-green-500 duration-300"
        >
          GitHub
        </a>
      </div>
    </div>
  );
};

const Projects = () => {
  return (
    <div className="bg-black">
      <div className="flex flex-wrap gap-7 justify-center items-center m-12 p-12">
        {project.map((item, index) => (
          <ProjectCard
            key={index}
            image={item.image}
            title={item.title}
            description={item.description}
            links={item.links}
            git={item.git}
            technologies={item.technologies}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export const project = [
  {
    title: "Myntra Clone",
    description:
      "Developed a Myntra clone with a fully functional cart page using React, Redux and Node. Users can add, update, and remove items, ensuring a seamless shopping experience.",
    image: "/projects/project_1.png",
    git: "https://github.com/Kushal1715/React/tree/main/Projects/myntra-clone",
    technologies: ["Redux", "ReactJS", "NodeJS"],
  },
  {
    title: "Digital Bazar",
    description:
      "Developed a full-stack e-commerce website using PHP, providing a comprehensive platform for online shopping with features such as product listings, user authentication, and a shopping cart.",
    image: "/projects/project_2.png",
    git: "https://github.com/Kushal1715/Project",
    technologies: ["PHP"],
  },
  {
    title: "News Hero",
    description:
      "Developed NewsHero using React, displaying news from various categories by integrating a public API.",
    image: "/projects/project_3.png",
    git: "https://github.com/Kushal1715/React/tree/main/Projects/newsapp",
    technologies: ["JavaScript", "Reactjs"],
  },
  {
    title: "Text Utils",
    description:
      "Developed TextUtils using React, a tool for editing text that also allows users to enable or disable dark mode.",
    image: "/projects/project_4.png",
    git: "https://github.com/Kushal1715/React/tree/main/Projects/textutils",
    technologies: ["JavaScript", "React"],
  },
];

export default Projects;
