
export const services = [
    {
        title: "Technology Enthusiast",
        icon: 'https://csgeeek.github.io/web.png',
    },
    {
        title: "Software Engineer",
        icon: 'https://csgeeek.github.io/web.png',
    },

];

export const name = 'Kushal Khadka';

export const experiences = [

    {
        'company': 'CodSoft',
        'role': 'React js Intern',
        'duration': 'July 2024 - present',
        'logo': '/codsoft.png',
        'points': [
            'Built a task tracker application that streamlined task management by implementing efficient state management, lifecycle methods, and form handling in React, leading to improved user experience and productivity.',
            'Created a product catalog for an e-commerce platform, integrating key e-commerce functionalities like product listings, detailed views, and a dynamic shopping cart, contributing to a seamless user experience and boosting site engagement.',
            'Developed a responsive personal portfolio website using React, focusing on modular component design and intuitive navigation, showcasing projects and skills to potential clients and employers'
        ],
        'url': 'https://www.codsoft.in/'
    },
]



